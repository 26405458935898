@import url("https://fonts.googleapis.com/css?family=Roboto:regular,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Inter:regular,bold,italic&subset=latin,latin-ext");

.App {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #fff;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* || Global Variables*/
:root {
  --blue: #1e90ff;
  --white: #fefefe;
  --black: #2c2f35;
  --lineColor: #e9e9e97e;
  --green: #107f62;
  --grey: #f6f6f6;
  --ag-border-color: #e9e9e9 !important;
  --orange: #ff6600;
}

/* || General styles */
a {
  color: inherit;
  text-decoration: none;
}

.success {
  color: #2fa124;
}

.warning,
.required {
  color: #ff6600;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.d-flex.column,
.d-flex.col {
  flex-direction: column;
}

.d-flex.center {
  justify-content: space-around;
  align-items: center;
}

.d-flex.bl {
  align-items: baseline;
}

.d-flex.al-start {
  align-items: flex-start;
}

.d-flex.al-end {
  align-items: flex-end;
}

.d-flex.al-center {
  align-items: center;
}

.d-flex.jc-center {
  justify-content: center;
}

.d-flex.end {
  justify-content: end;
}

.d-flex.start {
  justify-content: start;
}

.d-flex.center {
  justify-content: center;
}

.d-flex.sb {
  justify-content: space-between;
}
.d-flex.se {
  justify-content: space-evenly;
}
.d-flex.sa {
  justify-content: space-around;
}
.d-flex.w {
  flex-wrap: wrap;
}

.fs-12 {
  font-size: 12px;
}

.mr-2 {
  margin-right: 2%;
}

.mt-2 {
  margin-top: 2%;
}

.mt-1 {
  margin-top: 1%;
}

.ml-4 {
  margin-left: 4%;
}

.m-2 {
  margin: 2%;
}

.mb-12 {
  margin-bottom: 12%;
}

.mb-9 {
  margin-bottom: 9%;
}

.mb-8 {
  margin-bottom: 8%;
}

.mb-5 {
  margin-bottom: 5%;
}

.mb-3 {
  margin-bottom: 3% !important;
}

.p-l-20 {
  padding-left: 20px;
}

.pl-20 {
  padding-left: 20%;
}

.pl-5 {
  padding-left: 5%;
}

.pl-2 {
  padding-left: 2%;
}

.p-20 {
  padding: 20% !important;
}

.p-10 {
  padding: 10px;
}

.p-3 {
  padding: 3%;
}

.p-2 {
  padding: 2%;
}

.p-1 {
  padding: 1%;
}

.mh-80 {
  max-height: 80%;
}

.h-100 {
  height: 100%;
}

.h-95 {
  height: 95%;
}

.h-90 {
  height: 90%;
}

.h-85 {
  height: 85%;
}

.h-80 {
  height: 80%;
}

.h-72 {
  height: 72%;
}

.h-70 {
  height: 70%;
}

.h-64 {
  height: 64%;
}

.h-60 {
  height: 60%;
}

.h-50 {
  height: 50%;
}

.h-55 {
  height: 55%;
}

.h-40 {
  height: 40%;
}

.h-48 {
  height: 48%;
}

.h-45 {
  height: 45%;
}

.h-32 {
  height: 32%;
}

.h-33 {
  height: 33.33%;
}

.h-30 {
  height: 30%;
}
.h-25 {
  height: 25%;
}

.h-24 {
  height: 24%;
}

.h-20 {
  height: 20%;
}

.h-18 {
  height: 18% !important;
}

.h-15 {
  height: 15%;
}

.h-10 {
  height: 10%;
}

.h-8 {
  height: 8%;
}

.h-7 {
  height: 7%;
}

.h-5 {
  height: 5%;
}

.h-4 {
  height: 4%;
}

.w-100 {
  width: 100%;
}

.w-97 {
  width: 97%;
}

.w-96 {
  width: 96%;
}

.w-95 {
  width: 93%;
}

.w-90 {
  width: 90%;
}

.w-88 {
  width: 88%;
}

.w-85 {
  width: 85%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-50 {
  width: 50%;
}
.w-55 {
  width: 55%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-33 {
  width: 33.33%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-12 {
  width: 12%;
}

.w-4 {
  width: 4%;
}

.w-3 {
  width: 3%;
}

.w-2 {
  width: 2%;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hor-line {
  border-bottom: 1px solid var(--lineColor);
}

.hide {
  display: none;
}

.block {
  display: block;
}

.sharp-border {
  border-radius: 0px;
}
.rel {
  position: relative;
}
.abs {
  position: absolute;
}

/*Typography*/

.cont-title-font {
  font-size: 14px;
  font-weight: 600;
}
.f-14 {
  font-size: 14px;
}

/* || Main nav */

.workspace {
  height: 100%;
}

.full-workspace {
  width: calc(100%);
}
.pointer {
  cursor: pointer;
}

/*Layout */
.layout_ws {
  height: 90%;
  background-color: #e5e5e5;
  box-sizing: border-box;
  overflow: auto;
}

/*Layout */
.layout_mobile {
  height: 94%;
  background-color: #e5e5e5;
  box-sizing: border-box;
}

.disable {
  opacity: 0.4;
  pointer-events: none;
}

.noDisplay {
  display: none;
}

.loader {
  left: 0px;
  top: 0px;
  background: url("https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif")
    50% 50% no-repeat rgb(249, 249, 249);
}

.desktopDimensions {
  height: 100%;
  width: 100%;
}

.mobileDimensions {
  height: 100%;
  width: 100%;
}

.containerDesktop {
  width: calc(100% - 3rem);
}

input[type="text"]:focus {
  outline: 0;
}

/*react-select-async-paginate*/

.css-13cymwt-control {
  border: none !important;
  min-height: 100% !important;
}

.css-t3ipsp-control:hover {
  border-color: white !important;
  box-shadow: 0px white !important;
  min-height: 100% !important;
}

.css-t3ipsp-control {
  border-color: white !important;
  box-shadow: 0 0 0 0px white !important;
  transition: unset !important;
  min-height: 100% !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0px !important;
}

.css-1xc3v61-indicatorContainer {
  padding: 0px !important;
  color: #107f62 !important;
}

.css-15lsz6c-indicatorContainer {
  padding: 0px !important;
  color: #107f62 !important;
}

.css-qbdosj-Input {
  margin: 0px !important;
}

.scrollbarContainer {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.scrollbarContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.ag-theme-alpine .ag-root-wrapper {
  border: 1px solid #babfc7;
  border-color: #e9e9e9;
  --ag-borders-critical: 0.5px solid;
}

.ag-paging-panel {
  border-top-color: #e9e9e9;
}

.drawer-scrollable-list {
}

.drawer-scrollable-list::-webkit-scrollbar {
  width: 4px;
}

.drawer-scrollable-list::-webkit-scrollbar-track {
  background-color: #d9d9d9;
  border: none;
  border-radius: 2px;
}

.drawer-scrollable-list::-webkit-scrollbar-thumb {
  background-color: #838383;
  border-radius: 2px;
}

.pill-button {
  background: none;
  border: none;
  border-radius: 15px;
  font: inherit;
  cursor: pointer;
  text-transform: inherit;
  outline: inherit;
  padding: 8px 12px;
  padding-left: 1em;
  padding-right: 1em;
  font-weight: 500;
  background: #107f62;
  color: #fff;
  font-size: 13px;
}
