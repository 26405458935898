.tiny-scrollbar {
    
}
.tiny-scrollbar::-webkit-scrollbar {
    width: 6px;
}
.tiny-scrollbar::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 3px;
}
.tiny-scrollbar::-webkit-scrollbar-thumb {
    background: #a4a4a4;
    border-radius: 3px;
}
