.loginScreen {
    background: url("images/loginBackground.png") no-repeat center center fixed; 
    background-size: cover;
    padding-top: 15em;
    height: 100vh;
  }

  .loginscreenText {
    padding-left: 35%;
  }

  .loginContainer {
    display: flex;
    flex-direction: column;  
  }

  .loginButtonDiv {
    justify-content: center;
    align-items: center;
  }

  .loginButton {
    cursor: pointer;
    padding-top: 2%;
  }

  .errorScreen {
    height: 100vh;
    width: 100vw;
  }

  .errorContent {
    height: 80%;
    padding: 2% 2% 0% 2%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .errorShield {
    height: 70%;
    width: 100%;
  }

  .errorText {
    width: 100%;
    height: 30%;
    box-sizing: border-box;
    padding: 2% 10% 2% 32%;
  }

  .refreshButtonDiv {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .refreshButton {
    cursor: pointer;
    width: 25%;
    height: 100%;
    padding: 0% 5% 5% 5%;
    box-sizing: border-box;
  }
  
  .lds-hourglass {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 6px;
    box-sizing: border-box;
    border: 26px solid grey;
    border-color: #fff transparent grey transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

    /* @import '~bootstrap/dist/css/bootstrap.min.css'; */
